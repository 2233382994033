(function (root) {

    root.Polygons = root.Polygons || {};

    var P = root.Polygons, $ = root.jQuery;

    P.wpVersion = '2.1.1';

    P.modePath='';

    P.debugMode = 1;


    P.temp = {};

    P.app = {};

    P.url = {
        routeSegmentPath: '',
        initPagePath: ''
    };

    P.modelattr = {};

   
    P.isIE = function () {
        //var myNav = navigator.userAgent.toLowerCase();
        //return (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
        var match = navigator.userAgent.match(/(?:MSIE |Trident\/.*; rv:)(\d+)/);
        return match ? parseInt(match[1]) : false;
    };

    P.log = function () {
        return P.debugMode && 'console' in root && !P.isIE() ? root.console.log.apply(root.console, arguments) : null;
    };

    P.getScriptDir = function (jstag) {
        var path = jstag.src.replace(/\/script\.js$/, '/'),
            origin = 'origin' in root.location ? root.location.origin : root.location.protocol + "//" + root.location.hostname + (root.location.port ? ':' + root.location.port : '');
        path = path.replace(origin, '');
        return path.replace(path.replace(/^.*[\\\/]/, ''), '');
    };


    P.setUrlsPath = function (urls) {
        P.url = urls;
    };

    P.setModelattr = function (attr) {
        P.modelattr = attr;
    };

    var assetsDir = {
        appDir: null,
        widgetsDir: null,
        libraryDir: null,
        commonDir: null
    };

    P.setAppAssetsDir = function (key, value) {
        assetsDir[key] = value;
    };

    P.getAppAssetsDir = function (key) {
        if (assetsDir[key]) {
            return assetsDir[key];
        }
        P.log('assets directory with key ' + key + ' not defined');
    };

    var tinyMCEExternalCssFiles = [];

    P.setTinyMCEExtrnalCSSFile = function (file) {
        tinyMCEExternalCssFiles.push(file);
    };
    P.getTinyMCEExtrnalCSSFile = function () {
        return tinyMCEExternalCssFiles;
    };



    P.getRoutes = function () {
        return $.ajax({
            url: P.getmodePathUrl(P.url.routeSegmentPath),
            cache: false,
            type: "GET",
            data: {get: true},
            dataType: "json"
        });
    };


    P.getRegionsByPath = function (route) {
        if (route != undefined) {
            return $.map(P.temp.routes, function (el, ind) {
                if ((el.route) == route) {
                    return JSON.parse('{' + JSON.stringify(el.regions).match(/^.(.*).$/)[1] + '}');
                }
            })[0];
        }
    };

    P.setModePath=function(path) {
        this.modePath=path;
    };
    
    P.getModePath=function() {
        return this.modePath;
    };

    P.getmodePathUrl = function (path) {
        var prefix = this.getModePath();
        return prefix ? '/' + prefix + path : path;
    };

    var registredAfter = {};

    P.addToRegisteredAfter = function (id, obj) {
        registredAfter[id] = obj;
    };

    P.registerObject = function (objectId) {
        if (registredAfter[objectId] && typeof registredAfter[objectId] === typeof(Function)) {
            registredAfter[objectId]();
            delete registredAfter[objectId];
        }
    };
    
    P.registerAllObject = function () {
        var i;
        for (i in registredAfter) {
            P.registerObject(i);
        }
    };

    P.parseUri = function (str) {
        var	o   = P.parseUri.options,
            m   = o.parser[o.strictMode ? "strict" : "loose"].exec(str),
            uri = {},
            i   = 14;

        while (i--) uri[o.key[i]] = m[i] || "";

        uri[o.q.name] = {};
        uri[o.key[12]].replace(o.q.parser, function ($0, $1, $2) {
            if ($1) uri[o.q.name][$1] = $2;
        });

        return uri;
    };

    P.parseUri.options = {
        strictMode: false,
        key: ["source","protocol","authority","userInfo","user","password","host","port","relative","path","directory","file","query","anchor"],
        q:   {
            name:   "queryKey",
            parser: /(?:^|&)([^&=]*)=?([^&]*)/g
        },
        parser: {
            strict: /^(?:([^:\/?#]+):)?(?:\/\/((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?))?((((?:[^?#\/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
            loose:  /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/
        }
    };


})(window);
